import Bugsnag from '@bugsnag/js'
import { createSelector } from 'reselect'
import { emptyArr } from 'util/arrays'
import { emptyObj } from 'util/objects'
import { getActor } from 'util/actors'
import { getAgentUsername } from 'util/agents'
import { isTwitterComment } from 'util/changesets'
import { capitalize } from 'util/strings'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

// Map the changesets, merging any subsequent Agent and Group changes into one
// change, thus enabling this
//
//    and Assigned to @sumeet
//    and Assigned to Sumeet Group
//
// to be instead rendered like this
//
//    and Assigned to Sumeet Group (@sumeet)
//
export const combineAgentAndGroupActions = changeset => {
  if (!changeset.actions.length) return changeset

  let combineWithNextChangeset = false
  let agentChange

  const actions = changeset.actions.map((action, index) => {
    const nextAction = changeset.actions[index + 1]
    const isAgentAssignmentChange = action.change_type === 'Agent'
    const nextActionIsGroupChange =
      nextAction && nextAction.change_type === 'Group'

    if (isAgentAssignmentChange && nextActionIsGroupChange) {
      // set flag and exclude this changeset
      combineWithNextChangeset = true
      agentChange = action.change
      return undefined // since we're using map(), this will create an undef entry
    }

    if (combineWithNextChangeset) {
      // change the Group change into a AgentAndGroup change
      combineWithNextChangeset = false
      const combinedAction = {
        ...action,
        change_type: 'AgentAndGroup',
        agentChange, // add this prop so we know what kind of Agent change
      }
      agentChange = undefined
      return combinedAction
    }

    return action // otherwise do no mapping
  })

  // get rid of any undefs
  const squashed = actions.filter(e => {
    return e
  })

  return { ...changeset, actions: squashed }
}

export const labelForCurrentUser = (actor = {}, currentUser = {}) => {
  if (actor.id === currentUser.id || actor.email === currentUser.email) {
    return 'You'
  }
  return undefined
}

export const getTwitterHandle = actor =>
  actor.twitter && `@${actor.twitter.username}`

export const actorLabel = (
  actor,
  currentUser,
  fromTwitter = false,
  useFullName = false
) => {
  if (!actor) return ''
  try {
    if (actor.type === 'Rule') return 'Rule'
    if (actor.type === 'Integration') return actor.name
    return (
      labelForCurrentUser(actor, currentUser) ||
      capitalize(getAgentUsername(actor)) ||
      (useFullName ? actor.name : actor.first_name) ||
      (fromTwitter && getTwitterHandle(actor)) ||
      actor.email
    )
  } catch (err) {
    Bugsnag.notify(err, event => {
      event.addMetadata('metaData', {
        meta: { meta: { actor, currentUser, fromTwitter, useFullName } },
      })
    })
    return ''
  }
}

export const selectChangesetsByTicketId = state =>
  state.tickets.changesetsById || emptyObj

export const selectChangesetsForTicketId = (state, ticketId) => {
  return selectChangesetsByTicketId(state)[ticketId] || emptyObj
}

export const selectCurrentTicketChangesetCollection = createSelector(
  selectCurrentTicketId,
  selectChangesetsByTicketId,
  (ticketId, changesets) => {
    return changesets[ticketId]
  }
)

export const selectCurrentTicketRawActions = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => {
    return collection ? collection.actions : emptyArr
  }
)

export const selectCurrentRawActionsForTicket = (state, ticketId) => {
  const collection = selectChangesetsByTicketId(state)[ticketId]
  if (!collection) return null
  return collection.actions
}

export const selectCurrentRawChangesetsForTicket = (state, ticketId) => {
  const collection = selectChangesetsByTicketId(state)[ticketId]
  if (!collection) return null
  return collection.changesets
}

export const selectCurrentTicketChangesets = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => {
    return collection || emptyObj
  }
)

export const selectCurrentTicketMessages = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => {
    return collection ? collection.messages : emptyArr
  }
)

export const selectCurrentTicketChangesetsLoaded = createSelector(
  selectCurrentTicketId,
  selectChangesetsByTicketId,
  (ticketId, changesets) => {
    return !!changesets[ticketId]
  }
)

export const selectHasTicketLoadedChangesets = (state, ticketId) => {
  const byId = selectChangesetsByTicketId(state)
  return !!byId[ticketId]
}

export const selectFetchedChangesets = state => state.tickets.fetchedChangesets

export const selectIsChangesetFetched = (state, id) =>
  !!(state.tickets.fetchedChangesets && state.tickets.fetchedChangesets[id])

export const selectLastMessage = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => collection && collection.lastMessage
)

export const selectComputedReactions = createSelector(
  selectCurrentTicketChangesetCollection,
  collection => collection.computedReactions || emptyArr
)

export default {
  combineAgentAndGroupActions,
  getActor,
  actorLabel,
  isTwitterComment,
  getTwitterHandle,
}
