import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectCurrentTicketDraftByInferredType } from 'ducks/drafts2/selectors'
import { reverse } from 'util/arrays'
import { normalizeAttachment } from 'util/file'
import { selectAttachmentsByTicketId } from './base'

const emptyArray = []

export const selectUploadingAttachmentsForCurrentTicket = createSelector(
  selectAttachmentsByTicketId,
  selectCurrentTicketId,
  (byTicketId, ticketId) => {
    const attachments = byTicketId[ticketId]
    if (!attachments) return emptyArray
    return Object.values(byTicketId[ticketId]).map(normalizeAttachment)
  }
)

export const selectTicketDraftAttachments = createSelector(
  selectUploadingAttachmentsForCurrentTicket,
  selectCurrentTicketDraftByInferredType,
  (uploadingAttachments, draft) => {
    if (!draft || !draft.attachments) return uploadingAttachments
    const uploadedAttachments = Object.values(draft.attachments).map(
      normalizeAttachment
    )
    return [...uploadingAttachments, ...reverse(uploadedAttachments)]
  }
)

export const selectUploadingAttachmentByTicketIdAndFileName = createCachedSelector(
  selectAttachmentsByTicketId,
  (_state, _ticketId, name) => name,
  (_state, ticketId) => ticketId,
  (byTicketId, name, ticketId) => {
    if (!ticketId || !name) return null
    const attachments = byTicketId[ticketId]
    if (!attachments) return null
    return attachments[name]
  }
)((_state, name) => name || 'unknown')
