import { createSelector } from 'reselect'
import { isTwitter, isDirectMessage, isFacebook } from 'util/ticket'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'

export const selectIsTwitterTicket = createSelector(
  selectCurrentConversation,
  ticket => isTwitter(ticket)
)

export const selectIsTwitterDM = createSelector(
  selectCurrentConversation,
  ticket => isDirectMessage(ticket)
)

export const selectIsFacebookTicket = createSelector(
  selectCurrentConversation,
  ticket => isFacebook(ticket)
)
