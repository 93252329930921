/* eslint-disable no-multi-assign */
/* eslint-disable eqeqeq */ // for the one exception below
/* eslint-disable no-underscore-dangle */
import * as types from 'constants/action_types'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { updateTicketDirtyState } from 'reducers/tickets'
import { gcCheckAndRunForSearches } from 'util/gc/searches'

const reducers = {}
const defaultState = {}

function reduceBulkChangesetAction(state, { data: { tickets } }, type) {
  if (!tickets) return state
  let newState = Object.assign({}, state)
  const single = reducers[type]
  tickets.forEach(ticketData => {
    newState = single(newState, {
      type,
      data: ticketData,
      isBulk: true,
    })
  })
  return newState
}

reducers[types.CREATE_BULK_CHANGESET_REQUEST] = (state, action) => {
  return reduceBulkChangesetAction(
    state,
    action,
    types.CREATE_CHANGESET_REQUEST
  )
}

reducers[types.CREATE_BULK_CHANGESET_SUCCESS] = (state, action) => {
  return reduceBulkChangesetAction(
    state,
    action,
    types.CREATE_CHANGESET_SUCCESS
  )
}

reducers[types.LOGOUT] = () => {
  return undefined
}

reducers[types.SEARCH_COMPLETE] = (state, action) => {
  const { queryId } = action.data
  return gcCheckAndRunForSearches(state, queryId)
}

reducers[types.FETCH_TICKET_SUCCESS] = (state, action) => {
  const { ticket } = action.data
  if (ticket) {
    return gcRegisterFetchedTicket(state, ticket.id)
  }
  return state
}

const MAX_FETCHED_TICKETS = 50

function gcRegisterFetchedTicket(state, ticketId) {
  const { gcCandidates } = state.tickets
  const ticketAlreadyCandidate = gcCandidates.includes(ticketId)

  if (ticketAlreadyCandidate) return state

  if (gcCandidates.length < MAX_FETCHED_TICKETS) {
    return {
      ...state,
      tickets: {
        ...state.tickets,
        gcCandidates: [...gcCandidates, ticketId],
      },
    }
  }

  const currentTicketId = selectCurrentTicketId(state)
  const targetGcTicketId = gcCandidates.find(gcCandidateId => {
    return gcCandidateId !== currentTicketId
  })
  const newGcCandidates = gcCandidates.filter(gcId => gcId !== targetGcTicketId)
  return {
    ...state,
    tickets: {
      ...updateTicketDirtyState(
        {
          ...state.tickets,
          gcCandidates: [...newGcCandidates, ticketId],
          byId: {
            ...state.tickets.byId,
            [targetGcTicketId]: {
              ...state.tickets.byId[targetGcTicketId],
              actions: undefined,
              changesets: undefined,
            },
          },
        },
        targetGcTicketId,
        true
      ),
    },
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  let newState = state
  if (handler) newState = handler(state, action)
  return newState
}
