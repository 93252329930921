/* eslint-disable no-multi-assign */
import * as types from 'constants/action_types'
import { camelize, downcase } from 'util/strings'
import {
  INSERT_CANNED_REPLY_REQUEST,
  INSERT_CANNED_REPLY,
} from 'ducks/cannedReplies/types'
import {
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMERS_FAIL,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
} from 'ducks/customers/actionTypes'

const reducers = {}

const defaultState = {
  fetchingStatuses: {},
}

const updateFetchingStatus = (state, key, newStatus) => {
  return {
    ...state,
    fetchingStatuses: { ...state.fetchingStatuses, [key]: newStatus },
  }
}

// Creates a standard status-tracking reducer. The X_REQUEST action sets the
// fetchX status to true, and the _SUCCESS/_FAIL actions set it to false.
// Reduces boilerplate.
//
// e.g. Suppling an `actionPrefix` of FETCH_TICKET_ACTIONS maps to
// `app.statuses.fetchTicketActions`
//
// NOTE: has side effect on `reducers` object in current scope.
function trackFetchingStatus(actionPrefix) {
  const fetchLabel = camelize(downcase(actionPrefix))

  reducers[types[`${actionPrefix}_REQUEST`]] = state =>
    updateFetchingStatus(state, fetchLabel, true)

  reducers[types[`${actionPrefix}_SUCCESS`]] = reducers[
    types[`${actionPrefix}_FAIL`]
  ] = state => updateFetchingStatus(state, fetchLabel, false)

  return true
}

reducers[types.MARK_FETCHING_STATUS] = (state, { data: { action, status } }) =>
  updateFetchingStatus(state, action, status)

reducers[types.FETCH_FOLDER_COUNTS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchFolderCounts', true)

reducers[types.FETCH_FOLDER_COUNTS_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchFolderCounts', false)

reducers[types.SEARCH_USER_REQUEST] = state =>
  updateFetchingStatus(state, 'searchUsers', true)

reducers[types.FETCH_TOP_LABELS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchTopLabelsForSelection', true)

reducers[types.FETCH_TOP_LABELS_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchTopLabelsForSelection', false)

reducers[types.FETCH_TICKET_SNIPPETS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchSnippets', true)

reducers[types.FETCH_TICKET_SNIPPETS_SUCCESS] = reducers[
  types.FETCH_TICKET_SNIPPETS_FAIL
] = state => updateFetchingStatus(state, 'fetchSnippets', false)

reducers[types.FETCH_OPTIMISTIC_MERGE_TICKETS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchOptimisticMergeTickets', true)

reducers[types.FETCH_OPTIMISTIC_MERGE_TICKETS_SUCCESS] = state =>
  updateFetchingStatus(state, 'fetchOptimisticMergeTickets', false)

reducers[INSERT_CANNED_REPLY_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchCannedReplyForInsert', true)

reducers[INSERT_CANNED_REPLY] = state =>
  updateFetchingStatus(state, 'fetchCannedReplyForInsert', false)

reducers[types.SEARCHES_REQUEST] = state =>
  updateFetchingStatus(state, 'starredSearchCounts', true)

reducers[types.SEARCHES_SUCCESS] = reducers[types.SEARCHES_FAIL] = state =>
  updateFetchingStatus(state, 'starredSearchCounts', false)

reducers[UPDATE_CUSTOMER_REQUEST] = state =>
  updateFetchingStatus(state, 'savingCustomer', true)

reducers[UPDATE_CUSTOMER_SUCCESS] = reducers[UPDATE_CUSTOMER_FAIL] = state =>
  updateFetchingStatus(state, 'savingCustomer', false)

reducers[types.FETCH_CUSTOMERS_REQUEST] = state =>
  updateFetchingStatus(state, 'fetchCustomers', true)

reducers[FETCH_CUSTOMERS_SUCCESS] = reducers[FETCH_CUSTOMERS_FAIL] = state =>
  updateFetchingStatus(state, 'fetchCustomers', false)

trackFetchingStatus('FETCH_TICKET_ACTIONS')

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
