import { createSelector } from 'reselect'
import { selectAgentsByIdIncludingArchived } from 'selectors/agents/base'
import { selectGroupsById } from 'selectors/app/groups'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'
import { selectTicketsById } from '../byId/selectTicketsById'
import { decorateForInspector } from '../shared/util'

export const selectCurrentTicket = createSelector(
  selectTicketsById,
  selectCurrentTicketId,
  selectAgentsByIdIncludingArchived,
  selectCurrentUser,
  selectGroupsById,
  (byId, ticketId, agentsById, currentUser, groupsById) => {
    if (ticketId === 'new')
      return {
        id: 'new',
        actions: { records: [] },
      }
    if (!ticketId) return null
    const found = byId[ticketId]
    if (found) {
      return decorateForInspector(agentsById, currentUser, groupsById)(found)
    }
    return null
  }
)
