/* eslint-disable no-multi-assign */ // ok in reducers
import * as pages from 'constants/pages'
import * as types from 'constants/action_types'
import {
  constructSearchQueryObject,
  constructSearchQueryId,
  getSearchMailboxesFromQueryString,
} from 'util/search'
import { any, emptyArr, getLength, diff } from 'util/arrays'
import metrics from 'util/metrics'
import debug from 'util/debug'
import { sortOrderContextKeyForQueryId } from 'util/search/sorting'
import { createActionTypeReducer } from 'util/reducers'
import { CREATE_DRAFT, DELETE_DRAFT } from 'ducks/drafts2/constants'
import { CLEAR_SEARCH_TAG } from 'actions/search'
import { clearEditorAndAddBaseNode } from 'components/ConversationList/Header/Search/util'

const reducers = {}

const defaultState = {
  byQueryId: {},
  // committedSearchQueryString and submittedSearchQueryString won't include mailbox filter
  committedSearchQueryString: null,
  submittedSearchQueryString: null,
  // Mailbox filters, e.g. ['inbox1', 'inbox2']
  searchMailboxIds: [],
  // Store the current search query part that has the selection (clicked or typed)
  currentPart: { operator: undefined, value: undefined, part: undefined },
  // The editor for Search input
  editor: null,
  editorState: undefined,
}

reducers[types.MARK_FETCHING_STATUS] = (state, action) => {
  const data = action.data
  if (data.action === 'fetchTicketsByKeyword') {
    let isFetching = state.isFetching || false
    isFetching = data.status
    return Object.assign({}, state, {
      isFetching,
    })
  }
  return state
}

function immerUpdateDraftSearches(
  draftState,
  direction, // INCREMENT or DECREMENT
  ticketId,
  currentUserId,
  draftFolderIds
) {
  if (ticketId === 'new') return

  const changedSearchIds = Object.keys(draftState.byQueryId).reduce(
    (acc, search) => {
      // we remove from current user's draft search
      const matchesDraftSearch =
        currentUserId && search.match(`draft:${currentUserId}`)
      const matchesDraftFolder = any(
        id => search.match(`folder:${id}`),
        draftFolderIds
      )
      if (matchesDraftSearch || matchesDraftFolder) acc.push(search)
      return acc
    },
    []
  )

  if (getLength(changedSearchIds) === 0) return

  changedSearchIds.forEach(search => {
    const searchObject = draftState.byQueryId[search]

    if (!searchObject.removedTicketIds) {
      searchObject.removedTicketIds = {}
    }
    if (!searchObject.addedTicketIds) {
      searchObject.addedTicketIds = {}
    }
    if (!searchObject.pages) {
      searchObject.pages = []
    }

    // the page is not necessarily present, but count is
    // if page is present, get a new copy and store it for usage
    // later in the function
    // if (searchObject && searchObject.pages && searchObject.pages[1]) {
    //   searchObject.pages = { ...searchObject.pages }
    //   searchObject.pages[1] = [].concat(searchObject.pages[1]) // copy the array
    //   page = searchObject.pages[1]
    // }

    if (direction === 'INCREMENT') {
      if (searchObject.pages[1] && !searchObject.pages[1].includes(ticketId)) {
        searchObject.pages[1].push(ticketId)
      }

      // as in the root reducer, we track added(/removed) ticket ids so
      // that we dont apply diffs (e.g. from realtime updates) twice
      if (!searchObject.addedTicketIds[ticketId]) {
        searchObject.totalCount += 1
        searchObject.addedTicketIds[ticketId] = true
        delete searchObject.removedTicketIds[ticketId]
      }

      if (
        searchObject.sortedIds &&
        searchObject.sortedIds.indexOf(ticketId) < 0
      ) {
        if (searchObject.sortOrder === 'oldest') {
          searchObject.sortedIds.push(ticketId)
        } else {
          searchObject.sortedIds.unshift(ticketId)
        }
      }
    } else if (direction === 'DECREMENT') {
      if (searchObject.pages[1]) {
        const idx = searchObject.pages[1].indexOf(ticketId)
        if (idx > -1) {
          searchObject.pages[1].splice(idx, 1)
        }
      }

      if (!searchObject.removedTicketIds[ticketId]) {
        searchObject.totalCount -= 1
        searchObject.removedTicketIds[ticketId] = true
        delete searchObject.addedTicketIds[ticketId]
      }

      if (
        searchObject.sortedIds &&
        searchObject.sortedIds.indexOf(ticketId) > -1
      ) {
        searchObject.sortedIds = searchObject.sortedIds.filter(
          existingId => existingId !== ticketId
        )
      }
    } else {
      throw new Error('Invalid action')
    }

    // negative counts make us look like asses
    if (searchObject.totalCount < 0) searchObject.totalCount = 0
  })
}

reducers[CREATE_DRAFT] = (draftState, action) => {
  const { ticketId } = action.payload
  const { currentUserId, draftFolderIds = emptyArr } = action.meta

  immerUpdateDraftSearches(
    draftState,
    'INCREMENT',
    ticketId,
    currentUserId,
    draftFolderIds
  )
}

reducers[DELETE_DRAFT] = (draftState, action) => {
  const { ticketId } = action.payload
  const { currentUserId, draftFolderIds = emptyArr } = action.meta

  immerUpdateDraftSearches(
    draftState,
    'DECREMENT',
    ticketId,
    currentUserId,
    draftFolderIds
  )
}

// THIS IS MANAGING DRAFT COUNTS BASED ON IF THE BODY IS NOW EMPTY BECAUSE WE
// DON'T DELETE EMPTY DRAFTS AUTOMATICALLY

// if a draft changes, add or remove the ticket from appropriate search
// reducers[draftActionTypes.UPDATE_LOCAL_REPLY_DRAFT] = (state, action) => {
//   const {
//     ticketId,
//     currentUserId,
//     diff, // eslint-disable-line no-shadow
//     currentDraftBody,
//     draftFolderIds = emptyArr,
//   } = action.data
//   const body = diff ? diff.body : null
//   const byQueryId = state.byQueryId

//   const changedSearchIds = Object.keys(byQueryId).reduce((acc, search) => {
//     // we remove from current user's draft search
//     const matchesDraftSearch =
//       currentUserId && search.match(`draft:${currentUserId}`)
//     const matchesDraftFolder = any(
//       id => search.match(`folder:${id}`),
//       draftFolderIds
//     )
//     if (matchesDraftSearch || matchesDraftFolder) acc.push(search)
//     return acc
//   }, [])

//   if (getLength(changedSearchIds) === 0) return state

//   const updatedSearches = updateDraftSearches(
//     state.byQueryId,
//     changedSearchIds,
//     ticketId,
//     body,
//     currentDraftBody
//   )

//   return {
//     ...state,
//     byQueryId: {
//       ...byQueryId,
//       ...updatedSearches,
//     },
//   }
// }

reducers[types.SEARCHES_SUCCESS] = (state, action) => {
  const {
    trackMetrics,
    metricType,
    isAutomatic,
    isManual,
    foldersById,
    ticketSearchOperatorValueMap,
  } = action.data
  const newState = Object.assign({}, state)
  const searches = action.data && action.data.searches
  newState.byQueryId = Object.assign({}, newState.byQueryId)

  let metricOutput
  if (trackMetrics) {
    metricOutput = {
      correct: 0,
      incorrect: 0,
      correct_folder: 0,
      incorrect_folder: 0,
      correct_assignee: 0,
      incorrect_assignee: 0,
      correct_tag: 0,
      incorrect_tag: 0,
    }
  }

  Object.keys(searches).forEach(queryString => {
    const queryId = constructSearchQueryId(queryString)
    const query = newState.byQueryId[queryId]
    const newSearchData = searches[queryString]
    const newCount = newSearchData.count
    const currentCount = query && query.totalCount
    if (trackMetrics) {
      if (currentCount !== undefined && currentCount !== null) {
        const correct = currentCount === newCount
        if (correct) {
          metricOutput.correct += 1
          try {
            if (queryId.match('folder:')) metricOutput.correct_folder += 1
            if (queryId.match('assignee:')) metricOutput.correct_assignee += 1
            if (queryId.match('tag:')) metricOutput.correct_tag += 1
          } catch (e) {
            // pass
          }
        }
        if (!correct) {
          metricOutput.incorrect += 1
          try {
            if (queryId.match('folder:')) metricOutput.incorrect_folder += 1
            if (queryId.match('assignee:')) metricOutput.incorrect_assignee += 1
            if (queryId.match('tag:')) metricOutput.incorrect_tag += 1
          } catch (e) {
            // pass
          }
        }
      }
    }

    const newQuery = {
      ...query,
      timestamp: newSearchData.timestamp,
      queryId,
      queryObject: constructSearchQueryObject(queryString),
      totalCount: newCount,
      sortOrderContextKey: sortOrderContextKeyForQueryId(
        queryId,
        foldersById,
        ticketSearchOperatorValueMap
      ),
    }
    // count has changed, which means that something has changed in this search,
    // we need to update it on next load, so we panic and clear the search
    if (query && query.sortedIds && currentCount !== newCount) {
      if (debug.enabled) {
        // eslint-disable-next-line no-console
        console.debug({
          queryId,
          currentCount,
          newCount,
          sortedIdsCount: query.sortedIds.length,
        })
      }
      newQuery.pages = null
      newQuery.resultsIncomplete = true
    }
    newState.byQueryId[queryId] = newQuery
  })

  if (trackMetrics) {
    const payload = {
      logname: 'counts',
      metric: metricType || 'searchCounts',
      ...metricOutput,
      isAutomatic,
      isManual,
    }
    metrics.log(payload)
  }

  return newState
}

reducers[types.DELETE_TICKETS_SUCCESS] = reducers[
  types.DELETE_TICKETS_STARTED
] = (state, action) => {
  const data = action.data
  const tickets = data.tickets
  const ticketIds = tickets.map(t => t.id)

  return removeTickets(state, ticketIds)
}

function removeTickets(state, ticketIds, predicateFn = f => f) {
  const byQueryId = state.byQueryId || {}
  const newByQueryId = { ...byQueryId }
  const changedIds = Object.keys(newByQueryId).filter(predicateFn)

  if (getLength(changedIds) === 0) return state

  changedIds.forEach(id => {
    const search = (newByQueryId[id] = Object.assign({}, newByQueryId[id]))
    if (search.pages) {
      const searchPages = (search.pages = Object.assign({}, search.pages))
      Object.keys(searchPages).forEach(pageNumber => {
        const page = searchPages[pageNumber]
        searchPages[pageNumber] = diff(page, ticketIds)
      })
    }
    if (search.sortedIds) {
      const differenceTicketIds = diff(search.sortedIds || [], ticketIds)
      const shouldUpdateTotalCount = ticketIds.every(
        i => search.sortedIds.includes(i) && !differenceTicketIds.includes(i)
      )

      search.sortedIds = differenceTicketIds

      if (
        search.totalCount > 0 &&
        search.totalCount - ticketIds.length >= 0 &&
        shouldUpdateTotalCount
      ) {
        search.totalCount -= ticketIds.length
      }
    }
  })

  return {
    ...state,
    byQueryId: newByQueryId,
  }
}

reducers[pages.TICKET_PAGE] = (state, { payload: { id }, meta }) => {
  const newState = {
    ...state,
  }

  let prevId

  if (
    meta &&
    meta.location &&
    meta.location.prev &&
    meta.location.prev.payload
  ) {
    prevId = meta.location.prev.payload.id
  }

  const ticketPathChanged = id !== prevId

  if (!ticketPathChanged) return state

  return newState
}

reducers[types.UPDATE_CURRENT_TICKET_SEARCH_QUERY] = (state, action) => {
  const {
    data: {
      commit,
      queryString,
      currentPart = defaultState.currentPart,
      submit,
      reset,
    },
  } = action
  const { committedSearchQueryString, editorState } = state
  if (reset || submit) {
    return {
      ...state,
      currentPart: defaultState.currentPart,
      committedSearchQueryString: null,
      submittedSearchQueryString: submit ? queryString : null,
      editorState: reset ? undefined : editorState,
      searchMailboxIds: getSearchMailboxesFromQueryString(queryString),
    }
  }

  if (!queryString && queryString !== '') {
    return { ...state, currentPart }
  }

  const newCommittedSearchQueryString = commit
    ? queryString
    : committedSearchQueryString

  return {
    ...state,
    currentPart,
    committedSearchQueryString: newCommittedSearchQueryString,
  }
}

reducers[pages.TICKETS_PAGE] = state => {
  const searchEditor = state.editor
  if (searchEditor) {
    searchEditor.update(clearEditorAndAddBaseNode, {
      tag: CLEAR_SEARCH_TAG,
      onUpdate: () => {
        searchEditor.blur()
      },
    })
  }
  return {
    ...state,
    currentPart: defaultState.currentPart,
    committedSearchQueryString: null,
    submittedSearchQueryString: null,
    editorState: undefined,
    searchMailboxIds: [],
  }
}

reducers[pages.SEARCH_PAGE] = (draftState, action) => {
  const channels = action.meta?.query?.channel
  const search = action.meta?.query?.search
  if (channels) {
    draftState.searchMailboxIds = channels
  }
  if (search) {
    draftState.committedSearchQueryString = search
  }
  draftState.isReloadingSubmittedSearchQuery = true
  return draftState
}

reducers[types.TOGGLE_LIST_SEARCH_BOX_STATUS] = (state, action) => {
  if (!action.data) return state
  const {
    data: { isFocused },
  } = action

  return {
    ...state,
    listSearchBoxFocused: isFocused,
  }
}

reducers[types.SEARCH_MAILBOXES_UPDATE] = (state, action) => {
  return {
    ...state,
    searchMailboxIds: action.payload.mailboxes,
  }
}

reducers[types.SEARCH_UPDATE_BY_KEY] = (state, action) => {
  if (!action.payload.key) return state
  return {
    ...state,
    [action.payload.key]: action.payload.value,
  }
}

export default createActionTypeReducer(reducers, defaultState)
