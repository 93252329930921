import { compose, applyMiddleware, createStore } from 'redux'
import { combineReducers } from 'core/redux'
import ReduxThunk from 'redux-thunk'
import optimist from 'redux-optimist'
import { createLogger } from 'redux-logger'
import { connectRoutes } from 'redux-first-router'
import { agentCollisionsMiddleware } from 'ducks/collisions/utils'
import integrationMiddleware from 'util/integrations'
import { isFunction, isNotFunction } from 'util/functions'

// Reducers
import appReducer from 'reducers/app'
import importsReducer from 'ducks/imports/reducers'
import fetchingReducer from 'reducers/fetching'
import assignmentsReducer from 'reducers/assignments'
import ticketsReducer from 'reducers/tickets'
import searchReducer from 'reducers/search'
import labelsReducer from 'reducers/labels'
import userReducer from 'reducers/users'
import rawEmailReducer from 'reducers/raw_emails'
import rootReducer from 'reducers/root'
import modalsReducer from 'reducers/modals'
import menusReducer from 'reducers/menus'
import requestsReducer from 'reducers/requests'
import attachmentsReducer from 'reducers/attachments'
import webpushReducer from 'reducers/webPush'
import agentsReducer from 'reducers/agents'
import groupsReducer from 'reducers/groups'
import realtimeReducer from 'reducers/realtime'
import tagsReducer from 'ducks/tags'
import { refreshMiddleware } from 'util/refreshMiddleware'
import debugHistoryMiddleware from 'util/debugHistoryMiddleware'
import perf from 'util/perf'
// uncomment to test for memory usage difference
// import { middleware as memoryUsageMiddleware } from 'util/memoryUsageMiddleware'
// import { logReduxActionMiddleware } from 'util/performance_sim/actionsLoggerMiddleware'
import { cacheRegistry } from 'util/memoization'
import pageReducer from 'reducers/page'
import sidebarCardsReducer from 'reducers/sidebar_cards'
import userIntegrationsReducer from 'reducers/userIntegrations'
import emailMarketingReducer from 'reducers/email_marketing'
import kbReducer from 'subapps/kb/reducers'
import onboardingReducer from 'subapps/onboarding/reducers'
import { createEntityMiddleware } from 'ducks/entities/middleware'

import debug from 'util/debug'

import ducks from './ducks'
import ducksPage from './ducks/pageReducer'
import draftsDuck from './ducks/drafts2/reducers/drafts'

import routesMap from './routesMap'
import routeOptions from './routeOptions'

function createBulkThunkMiddleware() {
  return ({ dispatch, getState }) => next => action => {
    if (!action) {
      // eslint-disable-next-line no-console
      console.trace('ERROR DETECTED: Action is null. Debug using trace')
    }
    if (action.type === 'BATCH_ACTIONS') {
      action.actions
        .filter(isFunction)
        .map(actionFn => actionFn(dispatch, getState))
    }
    return next(action)
  }
}

function enableBatching(reducer) {
  return function batchingReducer(state, action) {
    switch (action.type) {
      case 'BATCH_ACTIONS':
        return action.actions
          .filter(isNotFunction)
          .reduce(batchingReducer, state)
      default:
        return reducer(state, action)
    }
  }
}

const {
  reducer: locationReducer,
  middleware: locationMiddleware,
  enhancer: locationEnhancer,
} = connectRoutes(routesMap, routeOptions)

export function createStoreReducer(dynamicReducers = {}) {
  return enableBatching(
    optimist((state, action) => {
      if (debug.enabled) perf.start(`Reducer: ${action.type}`)
      const rootReducedState = rootReducer(state, action)
      const result = combineReducers({
        app: { reducer: appReducer, includes: ['groups'] },
        fetching: fetchingReducer,
        tickets: ticketsReducer,
        drafts2: draftsDuck,
        assignments: assignmentsReducer,
        location: locationReducer,
        labels: labelsReducer,
        search: searchReducer,
        users: userReducer,
        rawEmails: rawEmailReducer,
        modal: modalsReducer,
        menus: menusReducer,
        requests: requestsReducer,
        attachments: attachmentsReducer,
        webPush: webpushReducer,
        agents: { reducer: agentsReducer, includes: ['currentUser'] },
        page: pageReducer,
        userIntegrations: userIntegrationsReducer,
        sidebarCards: sidebarCardsReducer,
        emailMarketing: emailMarketingReducer,
        onboarding: onboardingReducer,
        realtime: realtimeReducer,
        groups: groupsReducer,
        kb: kbReducer,
        tags: tagsReducer,
        imports: importsReducer,
        ducksPage,
        ...ducks,
        ...dynamicReducers,
      })(rootReducedState, action)
      if (debug.enabled) perf.stop(`Reducer: ${action.type}`)
      return result
    })
  )
}

const reducers = createStoreReducer()

const bulkThunk = createBulkThunkMiddleware()
const entitiesMiddleware = createEntityMiddleware()

const middleware = [
  cacheRegistry.middleware,
  ReduxThunk,
  bulkThunk,
  locationMiddleware,
  agentCollisionsMiddleware,
  integrationMiddleware,
  entitiesMiddleware,
  // Disable for now because of errors on bugsnag
  // https://app.bugsnag.com/groove/frontend/errors/6093f4d8e7b61200076a50d5?event_id=6093f4d8007a7b77f39b0000&i=sk&m=nw
  // TODO: (tayo) change to conditionally run only on accounts being perf simulated
  /* logReduxActionMiddleware, */
]

middleware.push(refreshMiddleware)
if (debug.enabled) middleware.push(debugHistoryMiddleware)

let composeEnhancers = compose

const env = process.env.NODE_ENV || 'development'
const logs = process.env.REDUX_LOGS || 'on'

let addLogger = false
if (env.match(/(development|staging|alpha|metal|beta)/)) {
  if (logs.match(/(on|hot)/)) addLogger = true
}

// Enable redux-devtools everywhere
const actionSanitizer = action => {
  if (action.type !== 'BATCH_ACTIONS') return action

  return Object.assign({}, action, {
    type: action.actions
      .map(a => a.type)
      .filter(x => x)
      .join(', '),
    originalType: 'BATCH_ACTIONS',
  })
}
/* eslint-disable no-underscore-dangle */
composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist,
        // actionsCreators, serialize...
        actionSanitizer,
        name: `${env} Groove`,
        maxAge: 1000,
      })
    : compose
/* eslint-enable */

// we cannot import app here, so we rely on localStorage
if (env === 'test') addLogger = false

if (addLogger) {
  middleware.push(
    createLogger({
      collapsed: true,
    })
  )
  // uncomment to test for memory usage difference
  // middleware.push(memoryUsageMiddleware)
}

const createStoreWithMiddleware = composeEnhancers(
  locationEnhancer,
  applyMiddleware(...middleware)
)(createStore)

const reduxStore = createStoreWithMiddleware(reducers)

export default reduxStore
