import { createSelector } from 'reselect'

import { intersection, uniq, getLength } from 'util/arrays'

import { selectCurrentTicketId } from 'ducks/tickets/selectors/selectCurrentTicketId'

import {
  selectSelectedTicketIds,
  selectIsTicketSelectionMode,
} from 'selectors/ticket_list/base'

import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import { isEmpty } from 'util/objects'
import { selectCurrentAgentsById } from 'ducks/agents/selectors'
import { selectCurrentTeamsById } from 'ducks/teams/selectors'
import { selectCurrentUserId } from 'ducks/currentUser/selectors/selectCurrentUserId'
import { assignmentLabel } from 'util/assignment'
import { getRawId } from 'util/globalId'
import { selectSearchEntityIdsByCurrentQueryId } from 'ducks/searches/selectors'

export const selectNextTicketIdInList = createSelector(
  selectSearchEntityIdsByCurrentQueryId,
  selectCurrentTicketId,
  (ids, currentTicketId) => {
    if (!ids) return null
    if (!currentTicketId) return null
    const currentTicketIndex = ids.indexOf(currentTicketId)
    return ids[currentTicketIndex + 1]
  }
)

export const selectAreAllSelectedStarred = createSelector(
  selectCurrentConversationsById,
  selectSelectedTicketIds,
  (tickets, selected) =>
    selected.reduce(
      (acc, id) => acc && tickets[id] && tickets[id].starred,
      true
    )
)

function findCommonState(tickets, selected) {
  const states = uniq(selected.map(id => tickets[id].state))
  return states.length === 1 ? states[0] : null
}

export const selectCommonStateForSelected = createSelector(
  selectCurrentConversationsById,
  selectSelectedTicketIds,
  (tickets, selected) => findCommonState(tickets, selected)
)

export const selectTicketSelectionCount = createSelector(
  selectSelectedTicketIds,
  selectIsTicketSelectionMode,
  (selected, isTicketSelectionMode) => {
    if (!isTicketSelectionMode) return undefined
    const length = getLength(selected)
    return length >= 0 ? length : 0
  }
)

// There can only be one!
function highlander(list) {
  if (isEmpty(list) || getLength(list) > 1) return null
  return list[0]
}

// Selects the ids of the assigned agents of the selected tickets, (includes nulls)
const selectAssignedAgentIdsForSelectedTickets = createSelector(
  selectCurrentConversationsById,
  selectSelectedTicketIds,
  (tickets, selected) =>
    selected.map(id => {
      return getRawId(tickets[id].assigned?.agent)
    })
)

const selectCommonAssignedAgentIdsForSelectedTickets = createSelector(
  selectAssignedAgentIdsForSelectedTickets,
  selectedAgentIds => {
    // because of how intersection works, each id needs to be in an array
    const agentIds = selectedAgentIds.map(id => [id])
    return intersection.apply(this, agentIds)
  }
)

export const selectCommonAssignedAgentsForSelectedTickets = createSelector(
  selectCommonAssignedAgentIdsForSelectedTickets,
  selectCurrentAgentsById,
  (commonAgentIds, agents) => {
    // eslint-disable-next-line no-confusing-arrow
    return commonAgentIds.map(id => (id ? agents[id] : null))
  }
)

export const selectCommonAssignedAgentForSelectedTickets = createSelector(
  selectCommonAssignedAgentsForSelectedTickets,
  highlander
)

// Selects the ids of the assigned groups of the selected tickets
const selectAssignedGroupsForSelectedTickets = createSelector(
  selectCurrentConversationsById,
  selectSelectedTicketIds,
  selectCurrentTeamsById,
  (tickets, selected, groupsById) =>
    selected.map(id => {
      const groupId = getRawId(tickets[id].assigned?.team)
      return groupsById[groupId]
    })
)

const selectCommonAssignedGroupIdsForSelectedTickets = createSelector(
  selectAssignedGroupsForSelectedTickets,
  selectedGroups => {
    // because of how intersection works, each id needs to be in an array
    const groupIds = selectedGroups.map(group => [group ? group.id : null])
    return intersection.apply(this, groupIds)
  }
)

export const selectCommonAssignedGroupsForSelectedTickets = createSelector(
  selectCommonAssignedGroupIdsForSelectedTickets,
  selectCurrentTeamsById,
  (commonGroupIds, teamsById) => {
    // eslint-disable-next-line no-confusing-arrow
    return commonGroupIds.map(id => (id ? teamsById[id] : null))
  }
)

// Selects the one common group across all selected tickets. Null if > 1
// groups are assigned among the selected, or no groups assigned at all.
export const selectCommonAssignedGroupForSelectedTickets = createSelector(
  selectCommonAssignedGroupsForSelectedTickets,
  highlander
)

export const selectAssignmentLabelForSelectedTickets = createSelector(
  selectCommonAssignedAgentForSelectedTickets,
  selectCommonAssignedGroupForSelectedTickets,
  selectCurrentUserId,
  assignmentLabel
)
