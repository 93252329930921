/* eslint-disable no-multi-assign */ /* this is ok in reducers */
import * as types from 'constants/action_types'
import { LABEL_FORM, LABELING_EDIT, LABEL_MANAGE } from 'constants/modal_types'
import { UPDATE_TAG_SUCCESS } from 'ducks/tags/actionTypes'
import { compact, isEmpty } from 'util/arrays'
import debug from 'util/debug'
import { keys, omit } from 'util/objects'
import { caselessMatch } from 'util/strings'
import { getRawId } from 'util/globalId'
import { reverseHashInt } from 'util/scatterSwap'

const defaultSelectionState = {
  added: [],
  removed: [],
  created: [],
}
const defaultState = {
  byId: {},
  byMailboxId: {},
  selection: defaultSelectionState,
  activeLabelIndex: 0,
  search: {},
  counts: {},
}
const reducers = {}

reducers[types.SEARCH_LABELS_REQUEST] = (state, action) => {
  const newState = Object.assign({}, state)
  const newSearch = (newState.search = Object.assign({}, newState.search))
  newSearch.previousTerm = newSearch.term
  if (action.data.storeTerm) newSearch.term = action.data.term

  if (!newSearch.previousTerm) newState.activeLabelIndex = 0

  return newState
}

reducers[types.UPDATE_LABEL_SEARCH_RESULTS] = (state, action) => {
  // have to build newSearch by key to make sure resultsByTerm is a new object
  const oldSearch = state.search || {}
  const newSearch = {
    resultsByTerm: Object.assign({}, oldSearch.resultsByTerm),
    term: oldSearch.term,
  }

  const labelCounts = Object.assign({}, state.counts)
  const labels = action.data.labels || []
  const newLabelCounts = Object.assign({}, labelCounts)
  const labelNames = []

  newSearch.resultsByTerm = newSearch.resultsByTerm || {}
  labels.forEach(label => {
    labelNames.push(label.id)
    newLabelCounts[label.id] = label.labelings_count
  })
  newSearch.resultsByTerm[action.data.term] = labelNames

  const newState = {
    search: newSearch,
    counts: newLabelCounts,
  }

  return Object.assign({}, state, newState)
}

reducers[types.CLEAR_LABEL_SEARCH_TERM] = state => {
  if (state.search.term === null) return state

  return {
    ...state,
    search: {
      ...state.search,
      term: null,
    },
  }
}

reducers[types.CLEAR_LABEL_SEARCH_RESULTS] = state => {
  const newState = Object.assign({}, state)
  newState.search = Object.assign({}, newState.search)
  newState.search.resultsByTerm = {}
  return newState
}

const defaultColor = '#58a2fb'

function mapById(labels = []) {
  /* eslint-disable no-param-reassign */
  return labels.filter(e => e).reduce((hash, label) => {
    const id = label.id.toString()
    hash[id] = {
      id,
      name: label.name,
      color: label.color || defaultColor,
      labelingsCount: label.labelingsCount || 0,
    }
    return hash
  }, {})
  /* eslint-enable no-param-reassign */
}

function updateById(state, labels) {
  if (!labels || isEmpty(labels)) return state
  return {
    ...state,
    byId: {
      ...state.byId,
      ...mapById(labels),
    },
  }
}

reducers[types.UNMARK_BULK_SELECTION_MODE] = reducers[
  types.ASSIGN_TICKETS_TO_AGENT
] = state => {
  return {
    ...state,
    selection: defaultSelectionState,
    search: { ...state.search, term: null },
  }
}

// If you close the Edit modal via clicking away, we clear whatever you selected
// Selection state will only persist via a LABEL_SELECTION_CLICK + applyAndClose
reducers[types.HIDE_MODAL] = (state, { data }) => {
  const { modalType } = data || {}
  if (!modalType || ![LABELING_EDIT, LABEL_FORM].includes(modalType)) {
    return state
  }

  return {
    ...state,
    selection: defaultSelectionState,
    search: { ...state.search, term: null },
  }
}

// Clear search term when opening modals
reducers[types.SHOW_MODAL] = (state, { data }) => {
  const { modalType } = data || {}
  if (!modalType || ![LABELING_EDIT, LABEL_MANAGE].includes(modalType)) {
    return state
  }

  return {
    ...state,
    search: { ...state.search, term: null },
  }
}

// When we fetch top labels, we update it for the mailbox, AND we also cache any
// labels not fetched by the bootstrap in byId.
reducers[types.FETCH_TOP_LABELS_SUCCESS] = (state, { data }) => {
  const { mailboxId, labels } = data

  return updateById(
    {
      ...state,
      byMailboxId: {
        ...state.byMailboxId,
        [mailboxId]: labels.map(l => l.id),
      },
    },
    labels
  )
}

// Detects if a label actually changed on ticket and needs updating in state.
// Allows for 'partial' labels (missing props). Wont update the store if nothing
// new detected.
function wasUpdated(label, newLabel) {
  if (!label) return true
  if (!newLabel) return false

  const { name, labelingsCount: count, color } = newLabel

  if (name && label.name !== name) return true
  if (count && label.labelingsCount !== count) return true
  if (color && label.color !== color) return true
  return false
}

function reduceTicketLabels(state, tickets) {
  let changed = false
  const labels = compact(
    // eslint-disable-next-line prefer-spread
    [].concat.apply([], tickets.map(ticket => ticket.labels))
  )
  if (!labels || isEmpty(labels)) return state

  const newById = { ...state.byId }
  labels.filter(e => e).forEach(label => {
    if (wasUpdated(state.byId[label.id], label)) {
      // merge it in - preserves color prop from bootstrap query.
      newById[label.id] = {
        color: defaultColor, // sane default
        ...state.byId[label.id],
        ...label,
      }
      changed = true
    } else {
      newById[label.id] = state.byId[label.id]
    }
  })

  if (!changed) return state
  // Perf: if you start seeing this msg a lot, youve got a problem
  debug('Labels changed!')

  return {
    ...state,
    byId: newById,
  }
}

reducers[types.CREATE_CHANGESET_SUCCESS] = reducers[types.ADD_CHANGESET] = (
  state,
  action
) => {
  return reduceTicketLabels(state, [action.data.ticketData])
}

reducers[types.UPDATE_ACTIVE_LABEL_INDEX] = (state, action) => {
  const { activeLabelIndex } = action.data
  return Object.assign({}, state, { activeLabelIndex })
}

function removeMatchingResults(state, name = '') {
  if (!name) return state
  const first = name.charAt(0)
  const { search = {} } = state

  return {
    ...state,
    search: {
      ...search,
      resultsByTerm: omit(
        keys(search.resultsByTerm || {}).filter(k =>
          caselessMatch(k.charAt(0), first)
        ),
        search.resultsByTerm
      ),
    },
  }
}

function handleCreateLabelSuccess(state, label, { addAsSelected = false }) {
  const { name } = label
  if (!addAsSelected)
    return removeMatchingResults(updateById(state, [label]), name)

  // Also add the newly created label to the list of selected tags.
  return removeMatchingResults(
    updateById(
      {
        ...state,
        selection: {
          ...state.selection,
          added: (state.selection.added || []).concat(label),
          created: (state.selection.created || []).concat(label),
        },
      },
      [label]
    ),
    name
  )
}

function handleUpdateLabelSuccess(state, label) {
  return updateById(state, [label])
}

// this syncs up the new settings tag create/update with the old redux store
reducers[UPDATE_TAG_SUCCESS] = (state, action) => {
  const { payload, meta: { addAsSelected } = {} } = action || {}
  const isCreate = !!payload.tagCreate
  const payloadRootName = isCreate ? 'tagCreate' : 'tagUpdate'
  const { [payloadRootName]: { errors, tag: { id, color, name } = {} } = {} } =
    payload || {}
  const savedTagDbId = reverseHashInt(getRawId(id)).toString()

  // create tag
  if (isCreate && !errors?.length) {
    return handleCreateLabelSuccess(
      state,
      { id: savedTagDbId, name, color },
      { addAsSelected }
    )
  }

  // update tag
  if (!isCreate && !errors?.length) {
    return handleUpdateLabelSuccess(state, { id: savedTagDbId, name, color })
  }

  return state
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
