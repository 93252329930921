import { createSelector } from 'reselect'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'
import { selectCurrentTicketId } from '../../ducks/tickets/selectors/selectCurrentTicketId'
import { selectTicketsById } from './byId/selectTicketsById'

export const selectedTicketsIncludesCurrentTicket = createSelector(
  selectCurrentTicketId,
  selectSelectedTicketIds,
  (currentTicketId, selectedTickets) => {
    return selectedTickets && selectedTickets.includes(currentTicketId)
  }
)

export const selectedTicketsAreAllDeleted = createSelector(
  selectTicketsById,
  selectSelectedTicketIds,
  (byId, selectedIds) => {
    return !selectedIds.some(id => {
      return byId[id] && byId[id].deleted_at
    })
  }
)
