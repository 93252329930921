import { createSelector } from 'reselect'
import { selectCurrentConversation } from 'ducks/tickets/selectors/selectCurrentConversation'
import { emptyArr } from 'util/arrays'
import { selectCurrentConversationsById } from 'ducks/tickets/selectors'
import { selectSelectedTicketIds } from './base'

export const selectSelectedTickets = createSelector(
  selectCurrentConversationsById,
  selectSelectedTicketIds,
  (byId, ticketIds) => {
    if (!ticketIds || ticketIds.length === 0) return emptyArr // perf: same empty array
    return ticketIds.map(id => byId[id])
  }
)

export const selectCurrentOrSelectedTickets = createSelector(
  selectSelectedTickets,
  selectCurrentConversation,
  (selected, current) => {
    return selected.length > 0 ? selected : [current]
  }
)
