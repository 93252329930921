import * as pages from 'constants/pages'

import { doSetupAuthCallbackPage } from 'actions/auth'

import ducksRoutesMap from 'ducks/routesMap'
import { routes as settingsRoutes } from 'subapps/settings/routes'
import { routes as onboardingRoutes } from 'subapps/onboarding/routes'
import { routes as subAppRoutes } from 'subapps'

const routesMap = {
  ...ducksRoutesMap,
  ...settingsRoutes,
  ...onboardingRoutes,
  ...subAppRoutes,
}

routesMap[pages.AUTH_CALLBACK_PAGE] = {
  path: '/auth/callback',
  thunk: doSetupAuthCallbackPage,
}

routesMap[pages.LOGIN_PAGE] = {
  path: '/login',
}

routesMap[pages.LOGIN_SELECTOR] = {
  path: '/login/selector',
}

routesMap[pages.LOGIN_MULTIPLE_ACCOUNTS_PAGE] = {
  path: '/login/accounts',
}

routesMap[pages.LOGIN_AGENT_MISSING_PAGE] = {
  path: '/login/not-found',
}

routesMap[pages.OTP_CHALLENGE_PAGE] = {
  path: '/login/otp',
}

routesMap[pages.FORGOT_PASSWORD_PAGE] = {
  path: '/users/password/new',
}

routesMap[pages.FORGOT_SUBDOMAIN_PAGE] = {
  path: '/users/subdomain/new',
}

routesMap[pages.CHANGE_PASSWORD_PAGE] = {
  path: '/users/password/edit',
}

routesMap[pages.LOGOUT_PAGE] = {
  path: '/logout',
}

routesMap[pages.MAIN_PAGE] = {
  path: '/',
}

routesMap[pages.MAILBOX_DEFAULT_PAGE] = {
  path: '/mailbox/',
}

routesMap[pages.MAILBOX_PAGE] = {
  path: '/mailbox/:mailboxId',
  fromPath: segment => `${segment}`, // dont convert id to a Number.
}

routesMap[pages.MAILBOX_FOLDER_PAGE] = {
  path: '/mailbox/:mailboxId/folders/:folderId',
  fromPath: segment => `${segment}`, // dont convert ids to Numbers.
}

routesMap[pages.NEW_CONVERSATION_PAGE] = {
  path: '/tickets/new',
}

routesMap[pages.LOG_CONVERSATION_PAGE] = {
  path: '/tickets/log-conversation',
}

routesMap[pages.RAW_EMAIL_PAGE] = {
  path: '/raw_email/:id',
  fromPath: segment => `${segment}`, // dont convert id to a Number.
}

routesMap[pages.SEARCH_PAGE] = {
  path: '/search/:id*',
}

routesMap[pages.TICKETS_PAGE] = {
  path: '/tickets',
}

routesMap[pages.TICKET_PAGE] = {
  path: '/tickets/:id',
}

routesMap[pages.TICKET_COMMENT_PAGE] = {
  path: '/tickets/:id/comment',
}

routesMap[pages.TICKET_REPLY_PAGE] = {
  path: '/tickets/:id/reply-all',
}

routesMap[pages.TICKET_REPLY_CHANGESET_PAGE] = {
  path: '/tickets/:id/reply-all/:changesetId',
}

routesMap[pages.TICKET_REPLY_DIRECT_PAGE] = {
  path: '/tickets/:id/reply',
}

routesMap[pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE] = {
  path: '/tickets/:id/reply/:changesetId',
}

routesMap[pages.TICKET_FORWARD_CHANGESET_PAGE] = {
  path: '/tickets/:id/forward/:changesetId',
}

routesMap[pages.TICKET_FORWARD_PAGE] = {
  path: '/tickets/:id/forward',
}

routesMap[pages.TICKET_CHANGESET_PAGE] = {
  path: '/tickets/:id/changeset/:changesetId/:hashId',
}

routesMap[pages.OAUTH_ERROR_PAGE] = {
  path: '/oauth-error',
}

export default routesMap
