import { createSelector } from 'reselect'

import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectCustomersById } from 'ducks/customers/selectors'
import { selectCurrentDraftContactId } from 'ducks/drafts2/selectors'
import { addActorLabelIfPresent } from '../shared/util'
import { selectCurrentConversation } from '../../../ducks/tickets/selectors/selectCurrentConversation'
import { selectIsTwitterTicket } from '../type'

// This style of selector avoids a huge decorated ticket object, and encourages
// components to use more specific selectors. This method improves our ability
// to find performance/rerendering issues; individual selectors misbehaving are
// easier to debug than a huge ticket decorator.
// CONVERTME we should no longer be using customers at all
export const selectCurrentTicketCustomer = createSelector(
  selectCurrentConversation,
  selectCustomersById,
  selectCurrentUser,
  selectIsTwitterTicket,
  (ticket, customersById, currentUser, isTwitterTicket) => {
    return addActorLabelIfPresent(
      customersById[ticket?.customer && ticket.customer.id],
      currentUser,
      isTwitterTicket
    )
  }
)

export const selectCurrentConversationContactId = createSelector(
  selectCurrentConversation,
  ticket => {
    return ticket && ticket.contact
  }
)

export const selectCurrentConversationOrConversationDraftContactId = createSelector(
  selectCurrentConversationContactId,
  selectCurrentDraftContactId,
  (ticketContactId, draftContactId) => {
    return ticketContactId || draftContactId
  }
)

export const selectCurrentConversationContactIdOrTemp = createSelector(
  selectCurrentConversation,
  selectCurrentConversationContactId,
  (ticket, contactId) => {
    return contactId || (ticket && `forConversation:${ticket.id}`)
  }
)

export const selectCurrentConversationOrConversationDraftContactIdOrTemp = createSelector(
  selectCurrentConversation,
  selectCurrentConversationOrConversationDraftContactId,
  (ticket, contactId) => {
    return contactId || (ticket && `forConversation:${ticket.id}`)
  }
)
