/* eslint-disable no-multi-assign */ // ok in reducers
import * as types from 'constants/action_types'
import * as pages from 'constants/pages'

import { DEFAULT_SORT_ORDER } from 'constants/defaults'

import { FETCH_CONVERSATION_CONTACT_PAGE_SUCCESS } from 'ducks/crm/contacts/types'
import deepEqual from 'fast-deep-equal'
import { diff, isEmpty, emptyArr, wrapInArray } from 'util/arrays'
import { isMessage } from 'util/changesets'

import { timeInHuman } from 'util/date'

import { isMobile } from 'util/media_query'
import { deepCopy } from 'util/objects'
import { reduceLabels, isTwitter, isFacebook } from 'util/ticket'
import {
  buildMessageCollection,
  getUpdatedTicketBodyAttributes,
} from 'util/messages'
import { SNOOZED_INDEFINITELY, snoozeOptions } from 'util/snooze'
import {
  getStateLabel,
  isOpen,
  isClosed,
  isSpam,
  isUnread,
  isDeleted,
  isCloseable,
  isStarred,
} from 'util/ticketState'
import {
  COMMENT_DELETION,
  COMMENT_EDIT,
} from 'constants/changesetActionChangeTypes'

// HACK (jscheel): Part of doMarkAsRead hack below.
import { UPDATE_PREFERENCES_SUCCESS } from 'ducks/currentUser/types'
import { BULK_UPDATE_CONVERSATIONS_STARTED } from 'ducks/tickets/actionTypes'
import { REMOVE_MAILBOX_LOCALLY } from 'ducks/mailboxes/actionTypes'
// END HACK

const defaultState = {
  byId: {
    new: { id: 'new', actions: { records: [] }, priority: 'low' },
  },
  isBulkSelectionMode: false,
  isMerging: false,
  optimisticMergeTicketsById: {},
  previousSortBy: DEFAULT_SORT_ORDER,
  selected: [],
  showMergeSnippets: false,
  snippetsById: {},
  sortBy: DEFAULT_SORT_ORDER,
  gcCandidates: emptyArr,
  lastSnoozedDate: null,
}

const defaultLoadedTicketState = {
  actions: { records: emptyArr },
  changesets: emptyArr,
}

const reducers = {}

const buildTicket = (original = {}, updated) => {
  const ticket = {
    ...original,
    ...updated,
    ...(updated && reduceLabels(updated.labels)),
  }

  const stateLabel = getStateLabel(
    ticket.state,
    ticket.snoozedUntil,
    ticket.deleted_at
  )

  if (deepEqual(original.bodyAuthor, ticket.bodyAuthor)) {
    ticket.bodyAuthor = original.bodyAuthor
  }

  if (deepEqual(original.tags, ticket.tags)) {
    ticket.tags = original.tags // maintain old array
  }

  return {
    ...ticket,
    assigneeId: ticket.assignee && ticket.assignee.id,
    assignedGroupId: ticket.assigned_group_id,
    updatedAtInHuman: timeInHuman(ticket.updated_at),
    latestCollaboratorCommentAtInHuman: timeInHuman(
      ticket.latest_collaborator_comment_at
    ),
    actions: null,
    titleLabel: ticket.title,
    customerId: ticket.customer && ticket.customer.id,
    stateLabel,
    snoozedUntil: ticket.snoozedUntil,
    hasAttachments: ticket.attachment_count > 0,
    isStarred: isStarred(ticket),
    isOpen: isOpen(ticket),
    isClosed: isClosed(ticket),
    isSpam: isSpam(ticket),
    isUnread: isUnread(ticket),
    isDeleted: isDeleted(ticket),
    isCloseable: isCloseable(stateLabel),
    isTwitterTicket: isTwitter(ticket),
    isFacebookTicket: isFacebook(ticket),
    linkedExternalResources: {
      ...original.linkedExternalResources,
      records:
        updated?.linkedExternalResources?.records ||
        original?.linkedExternalResources?.records,
    },
    channelType: 'email',
  }
}

reducers[types.DELETE_TICKETS_SUCCESS] = reducers[
  types.DELETE_TICKETS_STARTED
] = (state, action) => {
  const data = action.data
  const tickets = data.tickets
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  tickets.forEach(ticket => {
    delete newById[ticket.id]
  })
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[types.FETCH_TICKET_LINKED_RESOURCES_REQUEST] = (state, action) => {
  const { meta: { ticketId } = {} } = action
  return {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: {
        ...state.byId[ticketId],
        linkedExternalResources: {
          ...state.byId[ticketId].linkedExternalResources,
          loading: true,
        },
      },
    },
  }
}

reducers[types.INTEGRATIONS_ATTACH_TO_TICKET_SUCCESS] = (state, action) => {
  const { item: { id } = {} } = action
  const actions = action.item.actions
  if (!actions) return {}

  const records = actions.records
  if (!records) return {}

  const firstRecord = records[0]
  if (!firstRecord) return {}

  const change = firstRecord.change
  const newRecord = {
    externalId: change.external_id,
    linkedAt: firstRecord.created_at,
    provider: change.provider,
    title: change.title,
    url: change.url,
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      [id]: {
        ...state.byId[id],
        linkedExternalResources: {
          ...state.byId[id].linkedExternalResources,
          records: [
            ...(state.byId[id].linkedExternalResources.records || []),
            newRecord,
          ],
        },
      },
    },
  }
}

reducers[types.FETCH_TICKET_LINKED_RESOURCES_SUCCESS] = (state, action) => {
  const { meta: { ticketId } = {}, payload: linkedExternalResources } = action
  return {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: {
        ...state.byId[ticketId],
        linkedExternalResources: {
          records: linkedExternalResources,
          errored: false,
          loading: false,
        },
      },
    },
  }
}

reducers[types.FETCH_TICKET_LINKED_RESOURCES_FAIL] = (state, action) => {
  const { meta: { ticketId } = {} } = action
  return {
    ...state,
    byId: {
      ...state.byId,
      [ticketId]: {
        ...state.byId[ticketId],
        linkedExternalResources: {
          ...state.byId[ticketId].linkedExternalResources,
          errored: true,
          loading: false,
        },
      },
    },
  }
}

reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_REQUEST] = (state, action) => {
  const { sourceChangesetId } = action.data
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }

  fetchedChangesets[sourceChangesetId] = true

  return {
    ...state,
    fetchedChangesets,
  }
}

// CONVERT ME
reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS] = (state, action) => {
  const { ticketId, sourceChangesetId, actions } = action.data
  const collapsedSourceChangesetId = `${sourceChangesetId}-collapsed`
  const changesetsById = state.changesetsById || {}
  const collection = changesetsById[ticketId]

  // something is wrong, we fetched a changeset for a
  // ticket which has no changesets. Do not panic, simply
  // ignore this action
  if (!collection) return state

  const newActions = [].concat(collection.actions)

  // find the index of the action which caused the fetch
  // and replace it with actions that were fetched
  const collapsedIndex = newActions.findIndex(
    a => a.changeset === collapsedSourceChangesetId
  )
  if (collapsedIndex < 0) {
    return state
  }
  const collapsedChangeset = newActions[collapsedIndex]
  actions.forEach(singleAction => {
    // eslint-disable-next-line no-param-reassign
    singleAction.collapsedChangeset = collapsedChangeset
  })
  const spliceArgs = [collapsedIndex, 1].concat(actions)
  Array.prototype.splice.apply(newActions, spliceArgs)

  const newChangesetsById = { ...changesetsById }
  newChangesetsById[ticketId] = buildMessageCollection({
    records: newActions,
    oldCollection: collection,
  })

  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }
  delete fetchedChangesets[sourceChangesetId]

  return {
    ...state,
    fetchedChangesets,
    changesetsById: newChangesetsById,
  }
}

reducers[types.BULK_FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS] = (
  state,
  action
) => {
  const { ticketId, payloads } = action.data
  const changesetsById = state.changesetsById || {}
  const collection = changesetsById[ticketId]

  // something is wrong, we fetched a changeset for a
  // ticket which has no changesets. Do not panic, simply
  // ignore this action
  if (!collection) return state

  const newActions = [].concat(collection.actions)
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }

  payloads.forEach(single => {
    const { sourceChangesetId, actions } = single
    // find the index of the action which caused the fetch
    // and replace it with actions that were fetched
    const collapsedIndex = newActions.findIndex(
      a => a.changeset === sourceChangesetId
    )
    const collapsedChangeset = newActions[collapsedIndex]
    actions.forEach(singleAction => {
      // eslint-disable-next-line no-param-reassign
      singleAction.collapsedChangeset = collapsedChangeset
    })
    const spliceArgs = [collapsedIndex, 1].concat(actions)
    Array.prototype.splice.apply(newActions, spliceArgs)
    delete fetchedChangesets[sourceChangesetId]
  })

  const newChangesetsById = { ...changesetsById }
  newChangesetsById[ticketId] = buildMessageCollection({
    records: newActions,
  })

  return {
    ...state,
    fetchedChangesets,
    changesetsById: newChangesetsById,
  }
}

reducers[types.BULK_FETCH_CHANGESET_TICKET_ACTIONS_REQUEST] = (
  state,
  action
) => {
  const { changesetIds } = action.data
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }

  changesetIds.forEach(id => {
    fetchedChangesets[id] = true
  })

  return {
    ...state,
    fetchedChangesets,
  }
}

reducers[types.FETCH_CHANGESET_TICKET_ACTIONS_FAIL] = (state, action) => {
  const { sourceChangesetId } = action.data
  const oldFetchedChangesets = state.fetchedChangesets || {}
  const fetchedChangesets = { ...oldFetchedChangesets }
  delete fetchedChangesets[sourceChangesetId]

  return {
    ...state,
    fetchedChangesets,
  }
}

reducers[types.FETCH_TICKET_FAIL] = (state, action) => {
  const { ticketId, err } = action.data
  const byId = state.byId || {}
  const newById = { ...byId }
  const ticket = {
    id: ticketId,
    loadFailed: true,
    errors: err?.errors,
  }
  newById[ticket.id] = ticket

  return {
    ...state,
    byId: newById,
  }
}

reducers[types.MARK_BULK_SELECTION_MODE] = (state, { data }) => {
  const { selected } = state
  const { ticketIds } = data

  return Object.assign({}, state, {
    isBulkSelectionMode: true,
    selected: ticketIds.length === 1 ? ticketIds : selected,
  })
}

const deselectAll = state => {
  return {
    ...state,
    selected: [],
    isBulkSelectionMode: false,
    selectedPivotId: null,
    selectedRangeEndId: null,
  }
}

reducers[types.UNMARK_BULK_SELECTION_MODE] = reducers[
  types.ASSIGN_TICKETS_TO_AGENT
] = deselectAll

reducers[types.TOGGLE_TICKET_SELECTION] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (isTicketSelected) {
    const withoutTicket = selected.filter(selectedId => selectedId !== id)
    const isNowEmpty = isEmpty(withoutTicket)
    return {
      ...state,
      isBulkSelectionMode: !isNowEmpty,
      selected: withoutTicket,
      selectedPivotId: isNowEmpty ? null : state.selectedPivotId,
      selectedRangeEndId: isNowEmpty ? null : state.selectedRangeEndId,
    }
  }

  return {
    ...state,
    isBulkSelectionMode: true,
    selected: [...selected, id],
    selectedPivotId: id,
    selectedRangeEndId: null,
  }
}

const getRange = (arr, startIndex, endIndex) => {
  if (startIndex < endIndex) {
    return arr.slice(startIndex + 1, endIndex + 1)
  }
  return arr.slice(endIndex, startIndex).reverse()
}

reducers[types.RANGED_TICKET_SELECTION] = (state, action) => {
  const { selected } = state
  const { id, pivotId, rangeEndId, sortedMailboxTicketIds } = action.data
  const pivotIndex = sortedMailboxTicketIds.indexOf(pivotId)
  const rangeEndIndex = sortedMailboxTicketIds.indexOf(rangeEndId)
  const newEndIndex = sortedMailboxTicketIds.indexOf(id)

  // clear the old range
  const oldRange = getRange(sortedMailboxTicketIds, pivotIndex, rangeEndIndex)
  const newRange = getRange(sortedMailboxTicketIds, pivotIndex, newEndIndex)

  return {
    ...state,
    selected: diff(selected, oldRange).concat(newRange),
    selectedRangeEndId: id,
  }
}

reducers[types.SELECT_TICKET] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (isTicketSelected) {
    return state
  }
  return Object.assign({}, state, {
    selected: [...selected, id],
  })
}

reducers[types.DESELECT_TICKET] = (state, action) => {
  const { selected } = state
  const { id } = action.data
  const isTicketSelected = selected.indexOf(id) > -1

  if (!isTicketSelected) {
    return state
  }
  return Object.assign({}, state, {
    selected: selected.filter(selectedId => selectedId !== id),
  })
}

reducers[types.DESELECT_ALL_TICKETS] = state => {
  return Object.assign({}, state, {
    selected: [],
  })
}

function unique(value, index, self) {
  return self.indexOf(value) === index
}

reducers[types.SELECT_TICKETS] = (state, action) => {
  const { selected } = state
  const { ticketIds } = action.data
  let newSelected = selected || []
  newSelected = newSelected.concat(ticketIds || [])
  newSelected = newSelected.filter(unique)
  return Object.assign({}, state, {
    selected: newSelected,
    isBulkSelectionMode: true,
  })
}

function ensureActionsChangeToFieldIsArray(actions) {
  return actions.forEach(action => {
    if (action.change) {
      // eslint-disable-next-line no-param-reassign
      action.change.to = wrapInArray(action.change.to)
    }
  })
}

const applyChangeset = (initialState, data) => {
  let state = { ...initialState }

  const { ticketId, ticketData, changesetId, actions, actionsData } = data
  const commentDeletion = actions.find(
    action => action.change_type === COMMENT_DELETION
  )
  const commentEdit = actions.find(
    action => action.change_type === COMMENT_EDIT
  )
  // PK: for some reason initialState here is an old state, took even before CREATE_CHANGESET_REQUEST
  // it should be the newest state but it isn't. Changing it for all other actions would break them
  if (commentDeletion || commentEdit) {
    state = app.store.getState().tickets
  }

  const byId = state.byId || {}
  const changesetsById = state.changesetsById || {}
  // Ensure that the change object returned from the api has a to field
  // in the array format
  ensureActionsChangeToFieldIsArray(actions)
  const newById = { ...byId }
  let newChangesetsById = changesetsById || {}
  const found = changesetsById[ticketId]
  const currentActions = found ? found.actions : []

  const oldTicket = byId[ticketId] || defaultLoadedTicketState
  const newTicket = buildTicket(oldTicket, ticketData)

  if (currentActions && actions && actions.length > 0) {
    if (changesetId) {
      const records = currentActions.filter(
        action => action.changeset !== changesetId
      )
      const skippedChangeTypes = ['Rating', 'Ticket::CustomerActionOpen']
      const filteredActions = actions.filter(
        a => !skippedChangeTypes.includes(a.change_type)
      )

      const newActions = records.concat(filteredActions)

      const messageAction = filteredActions.find(isMessage)
      if (messageAction) {
        const { body, bodyType } = getUpdatedTicketBodyAttributes(messageAction)
        if (body) newTicket.body = body
        if (bodyType) newTicket.bodyType = bodyType
      }

      newChangesetsById = { ...changesetsById }
      newChangesetsById[ticketId] = buildMessageCollection({
        records: newActions,
        recordChanges: actionsData,
        oldCollection: found,
      })
    }
  }

  delete newTicket.changesets
  delete newTicket.actions

  newById[newTicket.id] = newTicket

  return {
    ...state,
    byId: newById,
    changesetsById: newChangesetsById,
  }
}

reducers[types.CREATE_CHANGESET_REQUEST] = reducers[
  types.CREATE_CHANGESET_SUCCESS
] = reducers[types.ADD_CHANGESET] = (state, { type, data, meta }) => {
  if (!data || !data.ticketId || !data.ticketData) return state
  let newState = applyChangeset(state, data)
  if (type === types.CREATE_CHANGESET_SUCCESS && meta?.isForNewTicket) {
    newState = {
      ...newState,
      byId: {
        ...newState.byId,
        new: {
          ...newState.byId.new,
          contactId: undefined,
        },
      },
    }
  }

  return newState
}

reducers[types.CREATE_BULK_CHANGESET_REQUEST] = (
  state,
  { data: { tickets } }
) => {
  if (!tickets) return state
  let newState = { ...state }
  const single = reducers[types.CREATE_CHANGESET_REQUEST]
  tickets.forEach(ticketData => {
    newState = single(newState, { data: ticketData })
  })
  return deselectAll(newState)
}

reducers[types.CREATE_BULK_CHANGESET_SUCCESS] = (
  state,
  { data: { tickets } }
) => {
  if (!tickets) return state
  const newState = { ...state }
  return deselectAll(newState)
}

// Apply bulk actions with a payload that has multiple tickets.
reducers[types.STAR_TICKETS_STARTED] = reducers[
  types.SNOOZE_TICKETS_REQUEST
] = (state, action) => {
  if (!action.data && !action.data.tickets) return state
  const { tickets } = action.data
  let newState = { ...state }
  tickets.forEach(ticket => {
    newState = applyChangeset(newState, ticket)
  })
  return newState
}

// When we kick off an Undo Send request, we do a two things. 1. We apply the
// optimistic UndoSend changeset that suppresses the (possibly in-flight)
// Message changeset, and 2. We restore the draft associated with this undo. The
// second step has been offloaded to the draft duck.
reducers[types.UNDO_SEND_REQUEST] = reducers[types.UNDO_SEND_SUCCESS] = (
  state,
  action
) => {
  const changesetFn = reducers[types.CREATE_CHANGESET_SUCCESS]
  return changesetFn(state, action)
}

reducers[types.TOGGLE_TICKETS_SORTING] = state => {
  const { sorting } = state

  if (sorting) {
    return Object.assign({}, state, {
      sorting: false,
    })
  }
  return Object.assign({}, state, {
    sorting: true,
  })
}

reducers[pages.TICKET_PAGE] = state => {
  // on desktop, its the same as a reply page
  if (!isMobile()) return state

  // on mobile, we have various janky jankness...
  return {
    ...state,
    // jank. isReplying already available in the route/location but desktop
    // doesnt use it (yet!)
    isReplying: false,
    isMerging: false, // FIXME - comes from page props now
  }
}

reducers[pages.TICKET_REPLY_PAGE] = reducers[
  pages.TICKET_REPLY_CHANGESET_PAGE
] = reducers[pages.TICKET_REPLY_DIRECT_PAGE] = reducers[
  pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE
] = reducers[pages.TICKET_FORWARD_CHANGESET_PAGE] = reducers[
  pages.TICKET_FORWARD_PAGE
] = reducers[pages.TICKET_FORWARD_PAGE] = state => {
  return {
    ...state,
    isMerging: false, // DEPRECATED: comes from page props now
  }
}

function clearSelected(state) {
  return {
    ...state,
    isBulkSelectionMode: false,
    selected: [],
    selectedPivotId: null,
    selectedRangeEndId: null,
  }
}

reducers[pages.FOLDER_PAGE] = reducers[pages.MAILBOX_FOLDER_PAGE] = reducers[
  pages.MAILBOX_PAGE
] = reducers[pages.MAIN_PAGE] = reducers[pages.SEARCH_PAGE] = reducers[
  pages.TICKETS_PAGE
] = reducers[pages.NEW_CONVERSATION_PAGE] = reducers[
  pages.LOG_CONVERSATION_PAGE
] = (state, action) => {
  const {
    type,
    payload,
    meta: {
      query: { folder, channel, orderBy } = {},
      location: {
        prev: {
          type: prevType,
          payload: prevPayload,
          query: {
            folder: prevFolder,
            channel: prevChannel,
            orderBy: prevOrderBy,
          } = {},
        } = {},
      } = {},
    } = {},
  } = action
  if (
    type !== prevType ||
    folder !== prevFolder ||
    channel !== prevChannel ||
    orderBy !== prevOrderBy ||
    !deepEqual(payload, prevPayload)
  ) {
    // only clear selected if there was an actual page/type change
    return clearSelected({
      ...state,
      // jank. isReplying already available in the route/location but desktop
      // doesnt use it (yet!)
      isReplying: false,
    })
  }

  return state
}

reducers[types.TOGGLE_SHOW_CC_BCC] = state => {
  const { showCcBcc } = state

  if (showCcBcc) {
    return Object.assign({}, state, {
      showCcBcc: false,
    })
  }
  return Object.assign({}, state, {
    showCcBcc: true,
  })
}

reducers[types.UPDATE_NOTE_FORM_HEIGHT] = (state, action) => {
  const newState = {}
  const { height } = action.data
  newState.noteFormHeight = height
  return Object.assign({}, state, newState)
}

reducers[types.FETCH_OPTIMISTIC_MERGE_TICKETS_SUCCESS] = (state, action) => {
  const { tickets } = action.data
  if (!tickets) return state

  const changesetsById = state.changesetsById || {}
  const newChangesetsById = { ...changesetsById }

  Object.values(tickets).forEach(ticket => {
    const actions = deepCopy(ticket.actions)

    if (actions && actions.records) {
      newChangesetsById[ticket.id] = buildMessageCollection(actions)
    }
  })

  return {
    ...state,
    changesetsById: newChangesetsById,
    optimisticMergeTicketsById: {
      ...state.optimisticMergeTicketsById,
      ...tickets,
    },
  }
}

reducers[types.FETCH_TICKET_SNIPPETS_SUCCESS] = (state, action) => {
  const { lastComments } = action.data
  if (!lastComments) return state
  const newSnippetsById = Object.assign({}, state.snippetsById || {})
  lastComments.map(snippet => {
    newSnippetsById[snippet.ticketId] = snippet.body
    return null
  })
  return Object.assign({}, state, {
    snippetsById: newSnippetsById,
  })
}

reducers[types.UPDATE_MERGE_SNIPPETS_VISIBILITY] = (state, action) => {
  const { visible } = action.data
  return Object.assign({}, state, {
    showMergeSnippets: visible,
  })
}

reducers[REMOVE_MAILBOX_LOCALLY] = (state, action) => {
  const { id } = action.data
  const byId = state.byId || {}
  const newById = Object.assign({}, byId)
  Object.values(byId).forEach(ticket => {
    if (ticket.mailboxId === id) {
      delete newById[ticket.id]
    }
  })
  return Object.assign({}, state, {
    byId: newById,
  })
}

reducers[BULK_UPDATE_CONVERSATIONS_STARTED] = state => {
  return deselectAll(state)
}

reducers[FETCH_CONVERSATION_CONTACT_PAGE_SUCCESS] = (
  state,
  { payload: { conversation: { contact, id, number } = {} } = {} }
) => {
  if (contact && contact.id) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [number]: {
          ...state.byId[number],
          gqlId: id,
          contactId: contact.id,
        },
      },
    }
  }
  return state
}

reducers[types.SAVE_LAST_SNOOZED_DATE] = (
  state,
  { data: { lastSnoozedDate } }
) => {
  // only saving custom snooze time that doesn't match the available predefined options
  if (!lastSnoozedDate || !(lastSnoozedDate instanceof Date)) {
    return {
      ...state,
      lastSnoozedDate: null,
    }
  }

  const predefinedSnoozeTimes = snoozeOptions()
    .filter(o => o.showOption() && o.value !== SNOOZED_INDEFINITELY)
    .map(o => o.timestamp())

  if (predefinedSnoozeTimes.includes(lastSnoozedDate.getTime())) {
    return {
      ...state,
      lastSnoozedDate: null,
    }
  }

  return {
    ...state,
    lastSnoozedDate: lastSnoozedDate.getTime(),
  }
}

reducers[types.UPDATE_APP_DATA] = (state, action) => {
  const { currentUser } = action.data
  if (!currentUser) return state
  const prefs = currentUser.preferences
  if (!prefs) return state
  return {
    defaultReplyState: prefs.defaultReplyState,
    reassignTicketOnReply: prefs.reassign_ticket_on_reply,
    reassignTicketOnNote: prefs.reassign_ticket_on_note,
    currentUserId: currentUser.id,
    ...state,
  }
}

reducers[UPDATE_PREFERENCES_SUCCESS] = (state, { payload }) => {
  return {
    ...state,
    reassignTicketOnReply: payload.reassign_ticket_on_reply,
    reassignTicketOnNote: payload.reassign_ticket_on_note,
  }
}

reducers[types.UPDATE_ACCOUNT_SUCCESS] = (state, { payload }) => {
  const newDefaultReplyState = payload?.updateAccount?.preferences?.reply_button

  if (typeof newDefaultReplyState !== 'boolean') {
    return state
  }

  return {
    ...state,
    defaultReplyState: newDefaultReplyState,
  }
}

export default function reducer(state = defaultState, action) {
  // this is here because a long reducer with many ifs is unreadable
  const handler = reducers[action.type]
  if (handler) return handler(state, action)
  return state
}
